<template>
  <div>
    <el-divider class="divider-carts" content-position="left">Воронка </el-divider>
    <v-chart class="chart-e h-550" :option="option" :step_chart="step_chart"  autoresize />
  </div>
</template>

<script>
import { mapGetters, } from "vuex";
import * as echarts from 'echarts/core';
import { i18n } from '@/utils/i18n';
import {
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { FunnelChart } from 'echarts/charts';

import { CanvasRenderer } from 'echarts/renderers';
import VChart, { THEME_KEY } from "vue-echarts";

echarts.use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  FunnelChart,
  CanvasRenderer
]);
export default {
  // name: "HelloWorld",
  components: {
    VChart
  },
  props: {
    step_chart: {
      default: null,
    },
  },
  provide() {
    return { [THEME_KEY]: this.mode ? "light" : "dark" };
  },
  data() {
    return {
      option: {
        title: {
          text: 'Funnel'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c}%'
        },
        toolbox: {
          feature: {
            dataView: { readOnly: false },
            restore: {},
            saveAsImage: {}
          }
        },
        legend: {
          data: ['new', 'called_interview', 'approved_employment']
        },
        series: [
          {
            name: 'Funnel',
            type: 'funnel',
            left: '15%',
            top: 60,
            bottom: 60,
            width: '70%',
            height: '90 %',
            min: 0,
            max: 100,
            minSize: '0%',
            maxSize: '100%',
            sort: 'descending',
            gap: 2,
            label: {
              show: true,
              position: 'inside'
            },
            labelLine: {
              length: 10,
              lineStyle: {
                width: 1,
                type: 'solid'
              }
            },
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 1
            },
            emphasis: {
              label: {
                fontSize: 20
              }
            },
            data: []
          }
        ]
      }

    }
  },
  mounted(){
    for (const key in this.step_chart) {
                  if (
                    this.step_chart.hasOwnProperty(
                      key
                        )
                    ) {
                        this.option.legend.data.push(
                            i18n.t('message.candidate_'+this.step_chart[key].name)
                        );
                        this.option.series[0].data[key] = {
                          'name': i18n.t('message.candidate_'+this.step_chart[key].name) ,
                          'value':this.step_chart[key].value,
                          'itemStyle':this.step_chart[key].itemStyle,
                        };
                        
                    }
                }
  },

  computed: {
    ...mapGetters({
      mode: 'MODE'
    }),
  },
}
</script>
<style>
.h-550{
  height: 550px !important;
}
</style>


