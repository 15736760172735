<template>
  <div>
    <el-divider class="divider-carts" content-position="left">Скорись    </el-divider>
    <v-chart class="chart-e h-480" :option="option" autoresize />
  </div>
</template>

<script>
import { mapGetters, } from "vuex";
import * as echarts from 'echarts/core';
import {
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { GaugeChart } from 'echarts/charts';

import { CanvasRenderer } from 'echarts/renderers';
import VChart, { THEME_KEY } from "vue-echarts";

echarts.use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  GaugeChart,
  CanvasRenderer
]);
export default {
  // name: "HelloWorld",
  components: {
    VChart
  },
  provide() {
    return { [THEME_KEY]: this.mode ? "light" : "dark" };
  },
  data() {
    return {
      option:  {
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            center: ['50%', '80%'],
            radius: '100%',
            min: 0,
            max: 1,
            splitNumber: 8,
            axisLine: {
              lineStyle: {
                width:15,
                color: [
                  [0.25, '#FF6E76'],
                  [0.5, '#FDDD60'],
                  [0.75, '#58D9F9'],
                  [1, '#7CFFB2']
                ]
              }
            },
            pointer: {
              icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
              length: '12%',
              width: 20,
              offsetCenter: [0, '-60%'],
              itemStyle: {
                color: 'inherit'
              }
            },
            axisTick: {
              length: 12,
              lineStyle: {
                color: 'inherit',
                width: 2
              }
            },
            splitLine: {
              length: 20,
              lineStyle: {
                color: 'inherit',
                width: 5
              }
            },
            axisLabel: {
              color: '#464646',
              fontSize: 20,
              distance: -60,
              rotate: 'tangential',
              formatter: function (value) {
                if (value === 0.875) {
                  return 'Grade A';
                } else if (value === 0.625) {
                  return 'Grade B';
                } else if (value === 0.375) {
                  return 'Grade C';
                } else if (value === 0.125) {
                  return 'Grade D';
                }
                return '';
              }
            },
            title: {
              offsetCenter: [0, '-10%'],
              fontSize: 20
            },
            detail: {
              fontSize: 30,
              offsetCenter: [0, '-35%'],
              valueAnimation: true,
              formatter: function (value) {
                return Math.round(value * 100) + '';
              },
              color: 'inherit'
            },
            data: [
              {
                value: 0.7,
                name: 'Grade Rating'
              }
            ]
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      mode: 'MODE'
    }),
  },
}
</script>
<style>
.h-480{
  height: 480px !important;
}
</style>

