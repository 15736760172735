<template>
  <div>
    <el-divider  class="divider-carts" content-position="left">Статус </el-divider>
    <v-chart class="chart-e" :option="option" autoresize />
  </div>
</template>

<script>
import { mapGetters, } from "vuex";
import * as echarts from 'echarts/core';
import {
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { BarChart } from 'echarts/charts';

import { CanvasRenderer } from 'echarts/renderers';
import VChart, { THEME_KEY } from "vue-echarts";

echarts.use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  CanvasRenderer
]);
export default {
  // name: "HelloWorld",
  components: {
    VChart
  },
  provide() {
    return { [THEME_KEY]: this.mode ? "light" : "dark" };
  },
  data() {
    return {
      option: {
        // backgroundColor: "transparent",
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {
          itemStyle: {
            // color: ['#FF0000'], // Set the desired background color for the legend square
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat',]
        },
        series: [
          {
            name: 'Резюме',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [100, 120, 120, 100, 80, 60, 90],
            itemStyle: {
              color: '#e7bcf3', // Set the desired background color for the legend square
            }
          },
          {
            name: 'Беседа',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [80, 70, 50, 60, 77, 50, 69],
            itemStyle: {
              color: '#9fe6b8',  // Set the desired background color for the legend square
            }
          },
          {
            name: 'Опит работа',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [70, 60, 50, 60, 77, 45, 60],
            itemStyle: {
              color: '#37a2da', // Set the desired background color for the legend square
            }
          },
          {
            name: 'Тест',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [ 60, 55, 50, 55, 60, 40, 55],
            itemStyle: {
              color: '#32c5e9', // Set the desired background color for the legend square
            }
          },
          {
            name: 'Ежемесячная оплата',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [ 3, 4, 6, 7, 4, 8, 2],
            itemStyle: {
              color: '#8378ea', // Set the desired background color for the legend square
            }
          }

        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      mode: 'MODE'
    }),
  },
}
</script>


