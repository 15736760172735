<template>
  <div>
    <el-divider class="divider-carts mt-3"  content-position="left">Источник</el-divider>
    <v-chart class="chart-e" :option="option" autoresize :social_chart="social_chart" />
  </div>
</template>

<script>
import { mapGetters, } from "vuex";
import * as echarts from 'echarts/core';
import {
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { BarChart,   } from 'echarts/charts';

import { CanvasRenderer } from 'echarts/renderers';
import VChart, { THEME_KEY } from "vue-echarts";

echarts.use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  CanvasRenderer
]);
export default {
  // name: "HelloWorld",
  components: {
    VChart
  },
  props: {
    social_chart: {
      default: null,
    },
  },
  provide() {
    return { [THEME_KEY]: this.mode ? "light" : "dark" };
  },
  data() {
    return {
      // color:['#cd181f', '#0077B5', '#002f34', '#fac858', '#0088cc', '#cd181f', '#0077B5', '#002f34', '#fac858', '#0088cc'],
      option: {
        // backgroundColor: "transparent",
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {
          itemStyle: {
            // color: ['#FF0000'], // Set the desired background color for the legend square
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: ['Источник']
        },
        series: []
      }
    }
  },
  mounted(){
    for (const key in this.social_chart) {
                  if (
                    this.social_chart.hasOwnProperty(
                      key
                        )
                    ) {
                      
                      this.option.series[key] = {
                            name: this.social_chart[key].name,
                            type: 'bar',
                            stack: 'total',
                            label: {
                              show: true
                            },
                            emphasis: {
                              focus: 'series'
                            },
                            data: [this.social_chart[key].value],
                            // itemStyle: {
                            //   color: this.color[key], // Set the desired background color for the legend square
                            // }
                          };
                       };
                        
                    }
  },
  computed: {
    ...mapGetters({
      mode: 'MODE'
    }),
  },
}
</script>


